import React from 'react';
import './App.css';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  avatar: {
    margin: 10,
  },
  card: {
    maxWidth: 345,
    backgroundColor: "#dfbbb1",
  },
  media: {
    height: 140,
  },
  bigAvatar: {
    margin: 10,
    width: 80,
    height: 80,
  },
  fancy: {
    fontFamily: 'Candara',
  },
  paperMain: {
    width: "40%",
    backgroundColor: "#dfbbb1",
    margin: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
  },
  paper: {
    backgroundColor: "#dfbbb1",
    margin: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
  },
  paperHeader: {
    margin: 10,
    fontFamily: 'Candara',
  },
  paperContent: {
    margin: 10,
  },
  rule: {
    width: "85%",
  },
  cards: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    flexGrow: 1,
  }
});

function App() {
  const classes = useStyles();

  return (
    <div className="App">
      <header className="App-header">
        <Avatar alt="Penny" src="pic.jpg" className={classes.bigAvatar} />
        <h1 className={classes.fancy}>PENNY'S PANTIES</h1>
      </header>
      <div className="App-main">
        <Paper elevation={3} className={classes.paperMain}>
          <header className={classes.paperHeader}><h2>USED PANTIES</h2></header>
          <hr className={classes.rule}/>
          <p className={classes.paperContent}>
            Are you a human with strange sexual desires or fantasies? Do you have a keen sense of smell? Do you still pleasure yourself to the last time your elbow accidentally grazed a woman's breast?
            My name is Penny and I wear all my panties myself. I have a very potent fragrance that is sure to turn you on. 
          </p>
          <p className={classes.paperContent}>
            Here you can find panties that covered my firm derriere, bras that helped support my supple tits, and more!
          </p>
          <p className={classes.paperContent}>
            I try to help out in any way that I can, so to give back to society I have pledged to donate one used pair of panties to the homeless for each purchase! 
            Help the homeless by helping yourself to my warm, wet panties.
          </p>
        </Paper>
        <Grid className={classes.cards}>
          <Grid item xs={12} md={12} lg={12}>
            <Grid container justify="center" spacing={2}>
                <Grid key={0} item>
                  <Card className={classes.card}>
                      <CardActionArea>
                        <CardMedia
                          className={classes.media}
                          image="pic2.jpg"
                          title="Yum"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="h2">
                            Best Seller!
                          </Typography>
                          <Typography variant="body2" color="textSecondary" component="p">
                            You put a pair of these in the smoker and the whole neighborhood will catch a whiff.
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions>
                        <Button size="small" color="primary">
                          Share
                        </Button>
                        <Button size="small" color="primary">
                          Add to Cart
                        </Button>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid key={1} item>
                  <Card className={classes.card}>
                      <CardActionArea>
                        <CardMedia
                          className={classes.media}
                          image="pic4.jpg"
                          title="Moist"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="h2">
                            A Particularly oderous garb
                          </Typography>
                          <Typography variant="body2" color="textSecondary" component="p">
                            I don't know what I did to produce this pheremone, but it seems potent.
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions>
                        <Button size="small" color="primary">
                          Share
                        </Button>
                        <Button size="small" color="primary">
                          Add to Cart
                        </Button>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid key={2} item>
                  <Card className={classes.card}>
                      <CardActionArea>
                        <CardMedia
                          className={classes.media}
                          image="pic3.jpg"
                          title="Greasy"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="h2">
                            Post Workout Bra
                          </Typography>
                          <Typography variant="body2" color="textSecondary" component="p">
                            After my cross fit routine these get really greasy. Self lubricating.
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions>
                        <Button size="small" color="primary">
                          Share
                        </Button>
                        <Button size="small" color="primary">
                          Add to Cart
                        </Button>
                    </CardActions>
                  </Card>
                </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default App;
